<template>
  <v-dialog
    v-model="dialog"
    dense
    max-width="600px"
    scrollable
    transition="dialog-top-transition"
  >
    <template #activator="{ on }">
      <v-btn color="primary" text v-on="on">
        {{ title }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ $t("items") }}
      </v-card-title>
      <v-card-text v-if="parsedItems?.length">
        <v-list>
          <v-list-item v-for="item in parsedItems" :key="item.name">
            {{ $t(item.name) }} = {{ item.value }}
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn depressed @click="dialog = false">
          {{ $t("close") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { AppModule } from "@/store/app";
import { set } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ReportJobFilterDialog extends Vue {
  @Prop({ type: Object, default: () => ({}) }) readonly items!: Record<
    string,
    any
  >;
  @Prop(String) readonly title!: string;

  filterName = [
    { name: "date", value: "date" },
    { name: "date.type", value: "date_type" },
    { name: "invoice.currency", value: "movementTypeCode" },
    { name: "client.provider", value: "customer_type" },
  ];

  filters = [
    "customer_type",
    "created_at_to",
    "credit",
    "date_type",
    "initialize",
    "invoice_customer",
    "movementTypeCode",
    "no_check_skip_balance",
    "show_content",
    "signed",
    "unpaid",
  ];

  dialog = false;

  get parsedItems() {
    // TODO eze obtener cliente
    if (!this.items || Object.keys(this.items).length === 0) {
      return [];
    }

    if (this.items.due_date_to || this.items.created_at_to) {
      this.items.date_type = this.items.due_date_to ? "Vencimiento" : "Fecha";
    }

    if (this.items.due_date_to) {
      this.items.date = this.items.due_date_to;
    } else if (this.items.created_at_to) {
      this.items.date = this.items.created_at_to;
    }

    this.items.customer_type = this.$t(this.items.customer_type);
    delete this.items.due_date_to;
    delete this.items.created_at_to;

    return this.filterName.map((filter) => {
      const item = Object.keys(this.items)
        .map((item: any) => {
          return {
            name: item,
            value: this.items[item],
          };
        })
        .filter((item) => filter.value.includes(item.name))[0];
      set(item, "name", filter.name);
      if (item.name == "invoice.currency") {
        set(item, "value", this.getCurrencyName(item.value));
      }
      return item;
    });
  }

  getCurrencyName(id: any) {
    const currency = AppModule.currencies.models.find(
      (currency) => currency.id === id
    );
    return currency ? `${currency.name} (${currency.code})` : "";
  }

  openDialog() {
    this.dialog = true;
  }

  closeDialog() {
    this.dialog = false;
  }
}
</script>
